import { useEffect } from "react";
import { toast } from "react-hot-toast";
import { Box, Stack, Typography, useTheme } from "@mui/material";
import { ChatInput } from "./ChatInput";
import { ChatMessage } from "../ChatMessage";
import { chatStore } from "../../store/chat-store";
import { useAskQuestion } from "../../api/useAskQuestion";
import { intellectaVersionStore } from "../../store/intellecta-version";
import { scrollChat } from "../../utilities/scrollChat";
import { DefaultChatMessage } from "./DefaultChatMessage";
import { InfoDialogButton } from "../InfoDialog";
import { useTranslate } from "@/i18n/config";
import {
  SENTRY_MODE_MESSAGES,
  SENTRY_MODE_TIMER_BETWEEN_MESSAGES_S,
  sentryModeStore,
} from "../../store/sentry-mode-store";

let index = 0;

export const ChatPanel = ({
  userInput,
  setUserInput,
}: {
  userInput: string;
  setUserInput: (inputText: string) => void;
}) => {
  const translate = useTranslate();
  const theme = useTheme();
  const { mutateAsync: askQuestion, isLoading } = useAskQuestion();
  const { messages, addMessage, setChatId, machine, isAnswering } = chatStore();
  const { isActive, resetTimer } = sentryModeStore();
  const intellectaVersion = intellectaVersionStore((state) => state.version);

  const submitHandler = () => {
    if (intellectaVersion === "troubleshooting" && !machine) {
      return toast.error(translate("intellecta.no_machine_selected"));
    }
    resetTimer();

    addMessage({
      sender: "User",
      message: userInput,
    });

    scrollChat();

    askQuestion({
      query: userInput,
    })
      .then((response) => {
        addMessage({
          sender: "Intellecta",
          message: response.value,
          image: response.image,
          id: response.message_id,
        });
        setChatId(response.chat_id);
      })
      .finally(() => {
        setUserInput("");
        resetTimer();
        scrollChat();
      });
  };

  useEffect(() => {
    let sentryModeInterval: NodeJS.Timeout | null;

    const printSentryModeMessage = () => {
      addMessage({
        message: SENTRY_MODE_MESSAGES[index],
        sender: "Sentry Mode",
      });

      index++;
      scrollChat();
    };

    if (isActive) {
      printSentryModeMessage();
      sentryModeInterval = setInterval(
        printSentryModeMessage,
        SENTRY_MODE_TIMER_BETWEEN_MESSAGES_S * 1000,
      );
    }

    return () => {
      if (sentryModeInterval) clearTimeout(sentryModeInterval);
    };
  }, [isActive, addMessage]);

  return (
    <Stack
      flex={1}
      bgcolor={theme.palette.background.default}
      position="relative"
      height="100%"
      // width="100%"
    >
      <Stack
        gap={2}
        flex={1}
        height="100%"
        width="100%"
        sx={{
          overflowY: "auto",
        }}
        id="chat-container"
        p={2}
      >
        {messages.length === 0 && <DefaultChatMessage />}
        {messages.map((message, i) => {
          return <ChatMessage key={i} {...message} />;
        })}
        {(isLoading || isAnswering) && (
          <ChatMessage
            sender="Intellecta"
            message={""}
            isLoading
            disableFeedback
          />
        )}
      </Stack>
      <Stack p={2} pb={0.5} alignItems="center">
        <ChatInput
          disabled={isLoading}
          onChange={(e) => {
            setUserInput(e.target.value);
          }}
          onSubmit={submitHandler}
          value={userInput}
        />
        <Typography pt={0.5} variant="caption" sx={{ color: "#ddd" }}>
          {translate("intellecta.disclaimer_2")}
        </Typography>
      </Stack>

      {/* <Box
        sx={{
          position: "absolute",
          right: 24,
          top: 12,
        }}
      >
        <NotificationsBadge notifications={NOTIFICATIONS} />
      </Box> */}
      <Box
        sx={{
          position: "absolute",
          right: 4,
          bottom: 4,
        }}
      >
        <InfoDialogButton />
      </Box>
    </Stack>
  );
};
