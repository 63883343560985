import { useFirebaseContext } from "@/context/firebase-context";
import { FetchClient } from "@/services/ApiClient";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-hot-toast";
import { ThingworxError } from "src/types/error";

type Response = ThingworxError | { response: true };

type Payload = {
  line_id: number;
  // line_output_id: string;
  line_outputs_ids: string[]; // solo string già raggruppate per line_layer
  target_oee: number;
  // reference_machine_id: string;
  reference_machines_ids: string[]; // solo string già raggruppate per line_layer
  reference_rate: number;
  output_rate: number;
};

export const useSetAutomaticConfig = () => {
  const { appKey } = useFirebaseContext();
  const client = useQueryClient();
  return useMutation({
    mutationFn: async (payload: Payload) => {
      return await FetchClient<Payload, Response>({
        appKey,
        payload,
        url: "dig.c.plantOverview_thing/Services/setAutomaticConfig",
      });
    },
    onSuccess(data, variables) {
      if (data.response) {
        toast.success("Configuration saved");
        client.invalidateQueries({
          queryKey: ["user-production-lines"],
        });

        client.invalidateQueries([
          "line-configuration",
          variables.line_id,
          "automatic",
        ]);
      }
    },
  });
};
