import { BarChart } from "@/components/highcharts/barchart/Barchart";
import { Piechart } from "@/components/highcharts/piechart/Piechart";
import { SelectTimeSelection } from "@/components/SelectTimeSelection";
import { useGetDataList } from "@/features/MachineDetail/api/CyclicHistoric/useGetDataList";
import { useGetHistoricData } from "@/features/MachineDetail/api/CyclicHistoric/useGetHistoricData";
import { GaugeLayout } from "@/features/MachineDetail/components/GaugeLayout";
import { ProductCounter } from "@/features/MachineDetail/components/ProductCounter";
import { withPieSteps } from "@/features/MachineDetail/utils/createPieSteps";
import { MachineViewNavBar } from "@/pages/MachineView/layout/MachineViewNavBar/MachineViewNavBar";
import { Box, Stack, Grid, Typography, Button } from "@mui/material";
import { useEffect, useState } from "react";

import { colors } from "@/styles/colors";
import { TimeHelpers } from "@/utils/TimeHelpers";
import { Card } from "@/components/Layout/Card";
import { Recipe } from "@/features/MachineDetail/components/Recipe";
import { CurrentMachineState } from "@/features/MachineDetail/components/CurrentMachineState";
import { AlarmColumns } from "@/features/Alarms";
import { AnimatePresence, motion } from "framer-motion";
import { ItemCard } from "@/components/ItemCard";
import { NavButton } from "@/components/NavButton";
import { DateTime } from "luxon";
import { useTimeSelection } from "@/store/useTimeSelection";
import { HistoricLoadingView } from "./HistoricLoadingView";
import { useCheckMachinePage } from "@/hooks/check-machine-page";
import { useTranslate } from "@/i18n/config";
import { fileNameFactory } from "@/utils/fileNameFactory";
import { useMachineContext } from "@/context/machine-context";
import { LineDetailButton } from "@/features/MachineDetail/components/LineDetailButton";

export const SentinelHistoricView = () => {
  const [selectedPeriod, setSelectedPeriod] = useState<{
    dataStartTimeLapse: number;
    dataEndTimeLapse: number;
    id: number;
  } | null>(null);
  const { machine } = useMachineContext();

  const { data: list } = useGetDataList();
  const translate = useTranslate();
  useEffect(() => {
    setSelectedPeriod(null);
  }, [list]);

  return (
    <>
      <MachineViewNavBar>
        <SelectTimeSelection
          exclude={["Custom", "Month", "ThreeMonths", "SixMonths", "Year"]}
          forceCalendar
        />

        {selectedPeriod ? (
          <Button onClick={() => setSelectedPeriod(null)}>
            {translate("go_back_to_select")}
          </Button>
        ) : null}
        <NavButton
          to="/machine-detail/sentinel/live"
          text={translate("view_live")}
        />
        {machine?.lineId && <LineDetailButton lineId={machine.lineId} />}
      </MachineViewNavBar>
      {selectedPeriod ? (
        <Stack gap={2} key={selectedPeriod.id}>
          <HistoricContentLayout
            id={selectedPeriod.id.toString()}
            dataStartTimeLapse={selectedPeriod.dataStartTimeLapse}
            dataEndTimeLapse={selectedPeriod.dataEndTimeLapse}
          />
        </Stack>
      ) : (
        <Stack spacing={2}>
          <Typography variant="h6" fontWeight="bold">
            {translate("please_select_a_period")}
          </Typography>
          <AnimatePresence presenceAffectsLayout mode="wait">
            <motion.div
              key={JSON.stringify(list?.result)}
              variants={{
                hidden: { opacity: 0 },
                show: {
                  opacity: 1,
                },
              }}
              initial="hidden"
              animate="show"
            >
              <Grid container spacing={2}>
                {list?.result.map((item) => (
                  <Grid item xs={6} md={3} key={item.id}>
                    <ItemCard
                      item={item}
                      onClick={(i) =>
                        setSelectedPeriod({
                          dataStartTimeLapse: i.dataStartTimeLapse,
                          dataEndTimeLapse: i.dataEndTimeLapse,
                          id: i.id,
                        })
                      }
                      subtitle={TimeHelpers.parseTimestampToString({
                        timestamp: item.dataStartTimeLapse,
                        fmt: "y LLL dd HH:mm:ss ",
                      })}
                      title={TimeHelpers.parseTimestampToString({
                        timestamp: item.dataStartTimeLapse,
                        fmt: "y LLL dd ",
                      })}
                      optionalLabel={item.timeLapse}
                    />
                  </Grid>
                ))}
              </Grid>
            </motion.div>
          </AnimatePresence>
        </Stack>
      )}
    </>
  );
};

function HistoricContentLayout({
  id,
  dataStartTimeLapse,
  dataEndTimeLapse,
}: {
  id: string;
  dataStartTimeLapse: number;
  dataEndTimeLapse: number;
}) {
  const {
    data: historicData,
    isLoading,
    error,
  } = useGetHistoricData({
    id: id,
  });

  const { machine } = useMachineContext();
  const machineName = machine?.machine;

  const translate = useTranslate();

  const { hasAccess } = useCheckMachinePage("stateLog");

  if (isLoading) {
    return <HistoricLoadingView />;
  }

  if (error) {
    return <div>{translate("user_feedback.an_error_occurred")}</div>;
  }

  if (!historicData) {
    return <div>{translate("user_feedback.no_data")}</div>;
  }

  const {
    alarmCountDuration,
    counter,
    kpi,
    production,
    recipe,
    recipeName,
    states,
    wasteCounter,
  } = historicData;

  const values = production[0];
  const drillDownValues = production[1];

  return (
    <>
      <Grid container columns={12} alignItems="center" spacing={2}>
        <Grid item xs={6} md={2}>
          <ProductCounter
            counter={counter}
            text={translate("machine.products")}
          />
        </Grid>
        <Grid item xs={6} md={2}>
          <ProductCounter
            counter={wasteCounter}
            size="small"
            color={colors.states.error}
            text={translate("machine.total_rejects")}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Stack gap={2}>
            <CurrentMachineState
              category={"Producing"}
              color={colors.categories.producing}
              state={"Log State"}
              linkTo={hasAccess ? "/state-log" : undefined}
              dateTimeHandler={() => {
                useTimeSelection.setState((prevState) => ({
                  ...prevState,
                  dates: {
                    dateStart: DateTime.fromMillis(dataStartTimeLapse),
                    dateEnd: DateTime.fromMillis(dataEndTimeLapse),
                  },
                }));
              }}
            />
            <Recipe
              recipeContent={recipe.Recipe.map((item) => ({
                ...item,
                Duration: TimeHelpers.parseDurationToString({
                  duration: item.Duration * 1000,
                }),
              }))}
              recipe={recipeName}
              recipeChanged={false}
              recipeClient={""}
            />
          </Stack>
        </Grid>
        <Grid alignItems="center" justifyContent="center" item xs={12} md={4}>
          <Box
            sx={{
              paddingX: "5%",
              height: {
                sx: "200px",
                md: "auto",
              },
            }}
          >
            <Piechart
              data={withPieSteps(states ?? [])}
              label=""
              title=""
              version="machineDetail"
            />
          </Box>
        </Grid>
      </Grid>
      <Grid id="machine-detail-gauge" container columns={8} alignItems="center">
        <Grid
          item
          sx={{ margin: "auto" }}
          md={1}
          alignItems="center"
          flex={1}
          justifyContent="center"
        >
          <GaugeLayout
            color={colors.kpi.availability}
            type="percentage"
            min={0}
            dimension="small"
            max={100}
            title={translate("kpi.availability")}
            value={kpi.availability}
          />
        </Grid>

        <Grid
          item
          sx={{ margin: "auto" }}
          md={1}
          alignItems="center"
          flex={1}
          justifyContent="center"
        >
          <GaugeLayout
            type="percentage"
            color={colors.kpi.performance}
            min={0}
            max={100}
            title={translate("kpi.performance")}
            dimension="small"
            value={kpi.performance}
          />
        </Grid>
        <Grid
          item
          sx={{ margin: "auto" }}
          md={1}
          alignItems="center"
          flex={1}
          justifyContent="center"
        >
          <GaugeLayout
            dimension="small"
            min={0}
            color={colors.kpi.quality}
            max={100}
            type="percentage"
            title={translate("kpi.quality")}
            value={kpi.quality}
          />
        </Grid>
        <Grid
          item
          sx={{ margin: "auto" }}
          md={2}
          alignItems="center"
          flex={1}
          justifyContent="center"
        >
          <GaugeLayout
            dimension="large"
            min={0}
            color={colors.kpi.oee}
            max={100}
            type="percentage"
            title={translate("kpi.oee")}
            value={kpi.oee}
          />
        </Grid>
        <Grid
          item
          sx={{ margin: "auto" }}
          md={1}
          alignItems="center"
          flex={1}
          justifyContent="center"
        >
          <GaugeLayout
            dimension="small"
            color={colors.kpi.elapsedTime}
            min={0}
            max={kpi.allTurnTime * 1000}
            type="duration"
            title={translate("machine.elapsed_time")}
            value={kpi.allTurnTime * 1000}
          />
        </Grid>
        <Grid
          item
          sx={{ margin: "auto" }}
          md={1}
          alignItems="center"
          flex={1}
          justifyContent="center"
        >
          <GaugeLayout
            dimension="small"
            type="duration"
            min={0}
            color={colors.kpi.inactiveTime}
            max={kpi.allTurnTime * 1000}
            title={translate("machine.inactive_time")}
            value={kpi.inactiveTime * 1000}
          />
        </Grid>
        <Grid
          item
          sx={{ margin: "auto" }}
          md={1}
          alignItems="center"
          flex={1}
          justifyContent="center"
        >
          <GaugeLayout
            dimension="small"
            type="duration"
            color={colors.kpi.uptime}
            min={0}
            max={kpi.allTurnTime * 1000}
            title={translate("machine.uptime")}
            value={kpi.uptimeSs * 1000}
          />
        </Grid>
      </Grid>
      <Card>
        <BarChart.Custom
          filename={fileNameFactory({
            name: "products_and_wastes",
            machine: machineName,
          })}
          drillDownOptions={
            drillDownValues && {
              series:
                drillDownValues.flatMap((drillDownData) => {
                  return [
                    {
                      id: drillDownData.id + "-waste",
                      name: translate("machine.wastes"),
                      type: "column",
                      borderColor: "transparent",
                      data: drillDownData.data.map((d) => [
                        d.name,
                        d.valueWaste,
                      ]),
                    },
                    {
                      id: drillDownData.id + "-product",
                      name: translate("machine.products"),
                      type: "column",
                      borderColor: "transparent",
                      data: drillDownData.data.map((d) => [
                        d.name,
                        d.valueCounter,
                      ]),
                    },
                  ];
                }) || [],
            }
          }
          yAxisTitleOptions={{
            text: translate("machine_detail.count_products_wastes"),
          }}
          title={translate("machine_detail.count_products_wastes")}
          uom="units"
          tooltip={{
            enabled: true,
          }}
          legend={{ enabled: true }}
          series={[
            {
              name: translate("machine.wastes"),
              type: "column",
              stacking: "normal",
              color: colors.states.error,
              data: values?.map((value) => ({
                y: value.valueWaste,
                drilldown: value.drilldown + "-waste",
                name: value.name,
              })),
            },
            {
              name: translate("machine.products"),
              type: "column",
              stacking: "normal",
              color: colors.states.production,
              data: values?.map((value) => ({
                y: value.valueCounter,
                drilldown: value.drilldown + "-product",
                name: value.name,
              })),
            },
          ]}
        />
      </Card>
      <AlarmColumns alarmData={alarmCountDuration.result_v2} />
    </>
  );
}
