import { HighchartsCustomProvider } from "../highcharts";
import {
  HighchartsChart,
  Chart,
  XAxis,
  YAxis,
  Tooltip,
  Title,
  XRangeSeries,
} from "react-jsx-highcharts";
import {
  ChartOptions,
  PlotXrangeOptions,
  TooltipFormatterCallbackFunction,
  XrangePointOptionsObject,
  XAxisOptions,
  YAxisOptions,
} from "highcharts/highstock";
import { z } from "zod";
import { TimeHelpers } from "@/utils/TimeHelpers";

interface XRangeProps {
  title: string;
  data: XrangePointOptionsObject[];
  categories?: string[];
  borderRadius?: number;
  tooltipOutside?: boolean;
  tooltipFormatter?: TooltipFormatterCallbackFunction;
  seriesOptions?: PlotXrangeOptions;
  xAxisOptions?: XAxisOptions;
  yAxisOptions?: YAxisOptions;
  chartOptions?: ChartOptions;
  labelsWidth?: number;
  customHeight?: number;
  allowExporting?: boolean;
}

export class XRangeChart {
  static Custom({
    title,
    data,
    categories,
    borderRadius = 3,
    tooltipOutside = true,
    tooltipFormatter = function () {
      const pointData = z
        .object({
          x: z.number(),
          x2: z.number(),
          color: z.string(),
          yCategory: z.string(),
        })
        .safeParse(this.point);

      if (!pointData.success) return "";

      const { x, x2, color, yCategory } = pointData.data;

      const dateStart = TimeHelpers.parseTimestampToString({
        timestamp: x,
      });
      const dateEnd = TimeHelpers.parseTimestampToString({
        timestamp: x2,
      });
      return `Start: ${dateStart}<br>
      End: ${dateEnd}<br>
      <span style="color:${color}">●</span> <b>${yCategory}</b>`;
    },
    seriesOptions,
    xAxisOptions,
    yAxisOptions,
    chartOptions,
    labelsWidth,
    customHeight,
    allowExporting,
  }: XRangeProps) {
    return (
      <HighchartsCustomProvider>
        <HighchartsChart
          accessibility={{ enabled: false }}
          plotOptions={{}}
          time={{ useUTC: false }}
          exporting={{ enabled: allowExporting }}
        >
          <Chart
            backgroundColor={"transparent"}
            type="xrange"
            height={customHeight}
            zooming={{
              type: "x",
              resetButton: {
                position: {
                  align: "right",
                  verticalAlign: "top",
                  x: -30,
                  y: 0,
                },
                relativeTo: "plotBox",
              },
            }}
            {...chartOptions}
          />

          <Title style={{ color: "white" }} align="left">
            {title}
          </Title>
          <Tooltip
            useHTML
            outside={tooltipOutside}
            formatter={tooltipFormatter}
          />

          <XAxis
            labels={{ style: { color: "white" } }}
            type="datetime"
            title={{ text: null }}
            {...xAxisOptions}
          />

          <YAxis
            gridLineColor="#333333"
            labels={{
              style: {
                color: "white",
                width: labelsWidth,
              },
            }}
            title={{ text: null }}
            showLastLabel
            categories={categories}
            max={categories !== undefined ? categories.length - 1 : null}
            reversed
            {...yAxisOptions}
          >
            <XRangeSeries
              borderColor="transparent"
              data={data}
              turboThreshold={20000}
              borderRadius={borderRadius}
              {...seriesOptions}
            />
          </YAxis>
        </HighchartsChart>
      </HighchartsCustomProvider>
    );
  }
}
