import { useEffect } from "react";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { Stack, useTheme, styled, Box } from "@mui/material";
import { ArrowForwardIosSharp } from "@mui/icons-material";
import { useTranslate } from "@/i18n/config";
import { NewChatButton } from "./NewChatButton";
import { PowerwordButtonList } from "../Powerword/PowerwordButtonList";
import { PromptButtonList } from "../Prompt/PromptButtonList";
import { TroubleShootingMachineSelector } from "./TroubleShootingMachineSelector";
import { intellectaVersionStore } from "../../store/intellecta-version";
import { Config } from "../../types";
import { SentryMode } from "../SentryMode";
import { UserMenu } from "./UserMenu";

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&::before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharp sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

export const LeftDrawer = ({
  onPromptClick,
  config,
}: {
  onPromptClick: (prompt: string) => void;
  config: Config;
}) => {
  const translate = useTranslate();
  const { version, setVersion, setAvailableVersions } =
    intellectaVersionStore();
  const theme = useTheme();

  useEffect(() => {
    setAvailableVersions(config.versions);
    if (version === null && config.versions.length > 0) {
      setVersion(config.versions[0]);
    }
  }, [config.versions, setAvailableVersions, version, setVersion]);

  return (
    <Stack
      width={268}
      bgcolor={theme.palette.background.paper}
      alignContent="space-between"
      height="100%"
      overflow="hidden"
    >
      {config.services.includes("sentry") && version === "data-analysis" && (
        <SentryMode />
      )}
      <Stack p={2} flex={1} gap={2} overflow="auto">
        <Accordion>
          <AccordionSummary aria-controls="panel1-content" id="panel1-header">
            {translate("intellecta.power_word_other")}
          </AccordionSummary>
          <AccordionDetails
            sx={{
              overflowY: "auto",
            }}
          >
            {/* qua faccio direttamente partire la chiamata e voglio una stringa da mettere nella chat */}
            <PowerwordButtonList powerwords={config.powerwords} />
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary aria-controls="panel2-content" id="panel2-header">
            {translate("intellecta.prompt_other")}
          </AccordionSummary>
          <AccordionDetails>
            {/* qua setto input utente, ancora modificabile, in input di testo */}
            <PromptButtonList
              prompts={config.prompts}
              onPromptClick={(prompt) => onPromptClick(prompt.prompt)}
            />
          </AccordionDetails>
        </Accordion>
        {/* {version === "data-analysis" && <DataAnalysisMachineList />} */}
        {version === "troubleshooting" && <TroubleShootingMachineSelector />}
      </Stack>
      <Box p={2} display="flex" gap={1}>
        <UserMenu />
        <NewChatButton />
      </Box>
    </Stack>
  );
};
