import { useState } from "react";
import { NumericFormat } from "react-number-format";
import { Box, Stack, Typography, Divider, Button } from "@mui/material";
import { Configuration } from "../api/useGetLineConfiguration";
import { StartConfiguration } from "@/features/OverallLineEfficiency/components/StartConfiguration";
import { StopConfiguration } from "@/features/OverallLineEfficiency/components/StopConfiguration";
import { TimeHelpers } from "@/utils/TimeHelpers";
import { Card } from "@/components/Layout/Card";
import { HistoricConfiguration } from "@/features/OverallLineEfficiency/Pages/HistoricLineDetail/api/useGetHistoricLineData";

type ConfigurationKeys = keyof Configuration["configuration"];
const MappedConfigurationKeys: Record<ConfigurationKeys, string> = {
  machineOutput: "Machine(s) Output",
  machineReference: "Machine(s) Reference",
  productionTarget: "Production Target",
  scheduledArrivalTime: "Scheduled Arrival Time",
  startTime: "Start Time",
  targetOee: "Target OEE",
  outputRate: "Output Rate",
  referenceRate: "Reference Rate",
};

const parseConfigurationValue = (
  key: ConfigurationKeys,
  value: number | string | string[],
) => {
  switch (key) {
    case "machineOutput":
    case "machineReference":
      if (!Array.isArray(value)) break;
      return value.map((el, i) => {
        return i > 0 ? `, ${el}` : el;
      });
    case "targetOee":
      return `${value}%`;

    case "scheduledArrivalTime":
    case "startTime":
      if (Array.isArray(value)) break;
      return TimeHelpers.parseTimestampToString({
        timestamp: typeof value === "string" ? parseInt(value, 10) : value,
      });

    case "productionTarget":
      if (Array.isArray(value)) break;
      return (
        <NumericFormat
          value={value}
          displayType="text"
          thousandSeparator="."
          decimalSeparator=","
        />
      );
    default:
      return String(value);
  }
};

export const LineConfig = ({ config }: { config: Configuration }) => {
  const showControlButtons = config.type !== "automatic";
  return (
    <Card
      sx={{
        height: "100%",
      }}
    >
      <Stack gap={2}>
        <Typography variant="h6">Configuration</Typography>
        <Stack
          spacing={1.5}
          divider={<Divider />}
          maxHeight={400}
          overflow="auto"
          padding={2}
        >
          {Object.entries(config.configuration).map(([key, value]) => (
            <Stack key={key} direction="row" justifyContent="space-between">
              <Box>{MappedConfigurationKeys[key as ConfigurationKeys]}</Box>
              <Box>
                {parseConfigurationValue(key as ConfigurationKeys, value)}
              </Box>
            </Stack>
          ))}
        </Stack>
        {showControlButtons ? (
          <Stack>
            {config.is_running ? (
              <StopConfiguration config_id={config.id} />
            ) : (
              <StartConfiguration config_id={config.id} />
            )}
          </Stack>
        ) : null}
      </Stack>
    </Card>
  );
};

export const HistoricLineConfigViewer = ({
  config,
}: {
  config: HistoricConfiguration[];
}) => {
  const [page, setPage] = useState(0);

  const configPages = config.length;

  const showingConfig = config[page];

  return (
    <Card
      sx={{
        height: "100%",
      }}
    >
      <Stack gap={2}>
        <Typography variant="h6">Configuration</Typography>
        <Stack
          spacing={1.5}
          divider={<Divider />}
          maxHeight={400}
          overflow="auto"
          padding={2}
        >
          {showingConfig
            ? Object.entries(showingConfig).map(([key, value]) => (
                <Stack key={key} direction="row" justifyContent="space-between">
                  <Box>{MappedConfigurationKeys[key as ConfigurationKeys]}</Box>
                  <Box>
                    {parseConfigurationValue(key as ConfigurationKeys, value)}
                  </Box>
                </Stack>
              ))
            : null}
        </Stack>

        {configPages > 1 ? (
          <Stack direction={"row"} justifyContent="space-between">
            <Button
              variant="outlined"
              onClick={() => {
                if (page === 0) return;
                setPage((page) => page - 1);
              }}
              disabled={page === 0}
            >
              prev
            </Button>
            <Button
              variant="outlined"
              onClick={() => {
                if (page === configPages - 1) return;
                setPage((page) => page + 1);
              }}
              disabled={page === configPages - 1}
            >
              next
            </Button>
          </Stack>
        ) : null}
      </Stack>
    </Card>
  );
};
