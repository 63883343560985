import {
  Stack,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  TextField,
  Button,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { NumericFormat } from "react-number-format";
import { z } from "zod";
import { Line } from "../../api/useGetUserProductionLines";
import { DateTimePicker } from "@mui/x-date-pickers-pro";
import { useSetManualConfig } from "../../Pages/LineOverview/api/useSetManualconfig";
import { DateTime } from "luxon";
import { useTranslate } from "@/i18n/config";

const TimebasedConfigSchema = z.object({
  line_output_id: z.string(),
  production_target: z.number().min(0),
  scheduled_arrival_time: z.date().transform((v) => DateTime.fromJSDate(v)),
});

type TimebasedConfigSchema = z.infer<typeof TimebasedConfigSchema>;

export const TimebasedConfigForm = ({
  line,
  onSubmit,
}: {
  line: Line;
  onSubmit: () => void;
}) => {
  const translate = useTranslate();
  const { mutate: setManualConfig } = useSetManualConfig();

  const {
    handleSubmit,
    formState: { errors },
    control,
    watch,
  } = useForm<TimebasedConfigSchema>({
    defaultValues: {
      line_output_id: "",
    },
  });
  const lineOutputId = watch("line_output_id");
  return (
    <form
      onSubmit={handleSubmit((data) => {
        setManualConfig(
          {
            config_type: "time_based",
            line_id: line.line_id,
            line_output_id: data.line_output_id,
            production_target: data.production_target,
            scheduled_arrival_time: data.scheduled_arrival_time,
          },
          {
            onSuccess: () => onSubmit(),
          },
        );
      })}
    >
      <Stack gap={4} p={2}>
        <FormControl fullWidth>
          <InputLabel size="small" id="demo-simple-select-label">
            {translate("overall_line_efficiency.machine_line_output")}
          </InputLabel>
          <Controller
            name="line_output_id"
            control={control}
            rules={{
              required: {
                value: true,
                message: "Required",
              },
            }}
            defaultValue={lineOutputId}
            render={({ field: { onChange, ...rest } }) => (
              <Select
                {...rest}
                size="small"
                onChange={(e) => {
                  onChange(e.target.value);
                }}
                defaultValue={lineOutputId}
                label="Machine line output"
                error={!!errors.line_output_id}
              >
                {line.line_machines
                  .filter((machine) => machine.machine_type === "cyclic")
                  .map((machine) => {
                    console.log(machine);
                    if (machine.line_layer) {
                      return (
                        <MenuItem key={machine.name} value={machine.name}>
                          {machine.name}
                        </MenuItem>
                      );
                    } else {
                      return null;
                    }
                  })}
              </Select>
            )}
          />
          {errors.line_output_id ? (
            <FormHelperText error>
              {errors.line_output_id.message}
            </FormHelperText>
          ) : null}

          <FormHelperText error={!!errors.line_output_id}>
            {translate("overall_line_efficiency.line_efficiency_time_series")}
          </FormHelperText>
        </FormControl>

        <FormControl fullWidth>
          <Controller
            name="production_target"
            control={control}
            rules={{
              required: {
                value: true,
                message: "Required",
              },
              min: {
                value: 0,
                message: "Can't be less than 0",
              },
            }}
            render={({ field: { onChange, value } }) => {
              return (
                <NumericFormat
                  customInput={TextField}
                  size="small"
                  value={value}
                  name="production_target"
                  label="Production target"
                  helperText={
                    errors.production_target && errors.production_target.message
                  }
                  thousandSeparator
                  error={!!errors.production_target}
                  onValueChange={(v) => onChange(Number(v.value))}
                />
              );
            }}
          />
          <FormHelperText>
            {translate("overall_line_efficiency.production_target_description")}
          </FormHelperText>
        </FormControl>
        <FormControl fullWidth>
          <Controller
            name="scheduled_arrival_time"
            control={control}
            rules={{
              required: {
                value: true,
                message: "Required",
              },
              validate: {
                isFuture: (v) => v > DateTime.now() || "Can't be in the past",
              },
            }}
            render={({
              field: { ref, onBlur, onChange: controllerOnChange, ...field },
              fieldState: { error },
            }) => {
              return (
                <DateTimePicker
                  {...field}
                  onChange={(date) => date && controllerOnChange(date)}
                  value={field.value || null}
                  label="Scheduled arrival time"
                  minDate={DateTime.now()}
                  inputRef={ref}
                  slotProps={{
                    textField: {
                      onBlur,
                      error: !!error,
                      helperText: error?.message,
                      size: "small",
                    },
                  }}
                />
              );
            }}
          />
        </FormControl>

        <Stack direction="row" gap={4} justifyContent="end">
          <Button
            sx={{ alignSelf: "end" }}
            variant="contained"
            color="error"
            onClick={onSubmit}
          >
            {translate("actions.cancel")}
          </Button>
          <Button
            sx={{ alignSelf: "end" }}
            variant="contained"
            color="success"
            type="submit"
          >
            {translate("actions.save")}
          </Button>
        </Stack>
      </Stack>
    </form>
  );
};
