import { useFirebaseContext } from "@/context/firebase-context";
import { FetchClient } from "@/services/ApiClient";
import { useQuery } from "@tanstack/react-query";
import { ThingworxError } from "src/types/error";
import { z } from "zod";
import { LineConfig } from "../Pages/Edit/types";
import { useTimeSelection, TimeSelection } from "@/store/useTimeSelection";
import { QueryBuilder } from "@/utils/query-builder";
import { useOeeLineContextState } from "../Pages/LineOverview/context/useOeeLineContextState";

type UserLinesResponse = ThingworxError | UserLinesResult;

export const Machine = z.object({
  name: z.string(),
  machine_type: z.enum(["cyclic", "process"]),
  // nuovo flag per capire se utilizzarla per i calcoli di linea: DEFAULT true
  is_enabled: z.boolean(),
  // nuovo valore per descrivere livello della macchina nella linea
  line_layer: z.number().optional(),
});

export type Machine = z.infer<typeof Machine>;

export const Line = z.object({
  state: z.enum([
    "Producing",
    "Not Producing",
    "Idle",
    "Scheduled Downtime",
    "Not Configured",
  ]),
  efficiency: z.number(),
  line_id: z.number(),
  line_name: z.string(),
  factory_name: z.string(),
  line_machines: z.array(Machine),
  is_configured: z.boolean(),
  is_running: z.boolean(),
  line_configuration: LineConfig.optional(),
  has_view_from_first_product: z.boolean().optional(),
});

export type Line = z.infer<typeof Line>;

const UserLinesResult = z.object({
  lines: z.array(Line),
  response: z.literal(true),
});

type UserLinesResult = z.infer<typeof UserLinesResult>;

type Payload =
  | {
      account: string;
      config: "automatic";
      timeSelection: TimeSelection;
    }
  | {
      account: string;
      config: "manual";
    };

export const useGetUserProductionLines = () => {
  const { appKey, user } = useFirebaseContext();
  const { view_mode } = useOeeLineContextState();
  const { timeSelection } = useTimeSelection();
  const account = user?.email || "";

  const query = QueryBuilder.buildWithCondition({
    baseQuery: ["user-production-lines"],
    condition: view_mode === "automatic",
    query: [timeSelection],
  });

  return useQuery<UserLinesResult>({
    queryKey: query,
    queryFn: async () => {
      const response = await FetchClient<Payload, UserLinesResponse>({
        appKey,
        payload:
          view_mode === "automatic"
            ? {
                account,
                config: view_mode,
                timeSelection,
              }
            : {
                account,
                config: view_mode,
              },
        url: "dig.c.plantOverview_thing/Services/getUserProductionLines",
      });

      if (!response.response) {
        throw new Error(response.errorString);
      }

      return UserLinesResult.parse(response);
    },
    refetchInterval: 1000 * 60 * 20,
  });
};
